import React from "react";
import logo from "./icon-old.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <a
            className="title-container"
            href="https://apps.apple.com/us/app/photostudio-ai-images/id6473727896"
          >
            <img
              height={32}
              width={32}
              src={logo}
              className="App-logo"
              alt="logo"
            />
            <text className="title">PhotoStudio</text>
          </a>
          <div className="links-container">
            <a
              className="App-link"
              href="mailto:support@photostudio.tech?subject=About PhotoStudio"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
            <a
              className="App-link"
              href="https://encouraging-bovid-db8.notion.site/PhotoStudio-Terms-of-Use-and-Privacy-Policy-cf39fb57eb1a4613980413eceef99f99?pvs=74"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Privacy
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
